.case-study-desc {
    text-align: start;
}

.background-image.douai {
    overflow: hidden;
    border-bottom-left-radius: var(--flowly-border-radius);
    border-bottom-right-radius: var(--flowly-border-radius);
    position: relative;
    height: 40vh;
    background-image: url("../../assets/img/experiences/douai_reseau.png") !important;
    background-position: center;
    background-size: cover;
}

.case-study {
    background-color: #fef5e5;
}

.center-box {
    padding: 2.5em;
    background-color: #24385E;
    height: fit-content;
    border-radius: var(--flowly-border-radius);
    position: relative;
    top: -75px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .center-box h4 {
        color: white;
        font-weight: normal;
        margin: 0;
    }

        .center-box h4 span {
            color: white;
            font-weight: bold;
        }

    .center-box p {
        text-align: center;
        font-size: 14px;
    }

    .center-box .icon {
        position: absolute;
        top: 6px;
        right: 12px;
    }
.center-text {
    text-align: center;
    font-size: 18px;
}
.simple-text {
    font-size: 18px;
    text-align:justify;
}
.case-study .block {
    padding: 1em;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

    .case-study .block .img {
        width: 150px;
        height: 150px;
        background-color: rebeccapurple;
    }


@media screen and (min-width: 992px) {
}

@media screen and (max-width: 992px) {

    .case-study-desc {
        text-align: center;
    }

    .center-box p {
        text-align: start;
    }

    .case-study .block {
        flex-direction: column;
    }
}

@media screen and (max-width: 576px) {
    .case-study .block {
        flex-direction: ro;
        text-align: start;
    }
}
