

.nav-tabs {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    transition: 0.1 ease;
    border-radius: 0.25rem;
    justify-content: start;
}

.fading-white-bg {
    box-shadow: 0px 0px 10px 15px rgba(255, 255, 255, .6);
    background-color: rgba(255, 255, 255, .6);
}

.nav-tabs .nav-link {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 15rem !important;
    background-color: white;
    color: rgba(0, 0, 0, 0.35) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    margin: 0 0.25em;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link:hover {
    font-weight: 600;
    color: var(--flowly-orange) !important;
    border-color: var(--flowly-orange) !important;
    transition: all .1s ease-in;
}

.tab-content {
    padding: 0 !important;
    margin: 0 0.25em;
}

.section-media {
    padding-top: 1.4rem !important;
    justify-content: center;
    text-align: center;
}

    .section-media .media-image {
        position:static;
        width: 140%;
        height:75%;
        margin-right:5px;
        margin-top:80px;
        margin-bottom:0px;
    }
    .section-media .med-sidebar {
        position: static;
        margin-top:-2px;
        bottom: 0;
        left: 2%;
        width: 40px;
        display: flex;
        align-items: start;
        justify-content: center;
        z-index: 10;
        max-height: 500px;
    }

        .section-media .med-sidebar .smedia-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin:0;
        }

            .section-media .med-sidebar .smedia-content .smedia-divider {
                width: 2px;
                height: 100px;
                background-color: var(--flowly-orange);
                margin: 8px 0;
            }

            .section-media .med-sidebar .smedia-content .smedia-text {
                color: var(--flowly-orange);
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                text-transform: uppercase;
            }

.media-wrapper {
    padding: .75em;
    height: auto;
    font-size: 13px;
}

.media-wrapper .media-card {
    padding: 0.5em;
    overflow: hidden;
    height: 100%;
    border-radius: var(--flowly-border-radius);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    position: relative;
    z-index: 98;
}

.media-wrapper .media-card .media-card-img-container {
    height: 300px;
}

.media-wrapper .media-card .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out;
    border-radius: 1rem;
}

.media-card:hover .overlay {
    background-color: rgba(245, 127, 1, 0.8);
}

.media-wrapper .media-card .media-card-img-container img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
}
.media-card-content-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.5em;
}

.media-card-content-container .title {
    margin: 6px 0;
    font-size: 15px;
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    color: white;
    font-weight: 700;
}

.media-card-content-container .date {
    margin: 6.25px 0;
    font-size: 12px;
    color: white;
    font-weight: 600;
}

.media-card-content-container .desc {
    margin: 6px 0;
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    display: none;
    color: white;
    transition: 0.5s ease-in-out;
}

.media-card:hover .media-card-content-container .desc {
    display: block;
}
.media-card-content-container .read-more {
    margin: 6px 0;
    font-weight: 600;
    display: none;
}

.b-alt .b-ellipse-1, .b-alt .b-ellipse-2 {
    top: 10% !important;
    height: 1000px !important;
    opacity: 0.15 !important;
}

@media screen and (max-width: 992px) {
    .map-img {
        display: none;
    }
    .nav-tabs {
        justify-content: center;
    }
    .section-media {
        display: none !important;
    }
    .media-card-content-container .desc {
      display: block;
    }
    .media-wrapper .media-card .overlay {
        background-color: rgba(0, 0, 0, 0.6);
    }
}