.flowly-footer {
    padding: 30px 0; 
    background-color: var(--flowly-orange);
    color: #FFF;
    font-size: 14px;
    user-select: none;
}

.flowly-footer h6 {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0.75em;
    margin: 0;
}

.flowly-footer .logo-container {
    width: 100%;
    height:100%;
    display: flex;
    justify-content: left;
    flex-direction:column;
}

.flowly-footer .logo-container .smedia-footer {
    height:100%;
    display:flex;
}

.flowly-footer .logo-container .smedia-footer .mtb-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.list-link {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.list-link li {
    padding: 5px 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: start;
}

.list-link li p {
    margin: 0 0.5rem;
    vertical-align: center;
}

.list-link li a:hover {
    cursor: pointer;
    color: var(--flowly-body-color);
    transition: 0.1s ease-in;
}

.link {
    cursor: pointer;
    font-weight: bold;
    color: #FFF;
}

.link:hover {
    color: var(--flowly-body-color);
    transition: 0.1s ease-in;
}

.flowly-footer-bottom {
    color: #FFF;
}

.flowly-footer h6, .flowly-footer a {
    color: #FFF;
}

.flowly-footer-bottom {
    padding: 0.75em;
    background-color: var(--flowly-orange);
    font-size: 12px;
}

.footer-social-media {
    width: 2rem;
    height: 2rem;
    border-radius: 50px;
    border: 2px solid #FFF;
    margin-left: 0px;
}

.footer-social-media:hover, .footer-social-media:hover .icon {
    cursor: pointer;
    border-color: var(--flowly-body-color);
    transition: 0.1s ease-in;
    stroke: var(--flowly-body-color);
}

.smedia-footer h6 {
    padding-bottom:0px;
}

.flowly-footer .logo-container .colored-png {
    -webkit-transition: filter .2s ease-out;
    -moz-transition: filter .2s ease-out;
    transition: filter .2s ease-out;
}

.flowly-footer .logo-container .colored-png:hover {
    filter: invert(80%);
}


@media screen and (max-width: 991px) {
    .flowly-footer .container .row .col-12 {
        margin-top: 3rem!important;
    }
    .flowly-footer .container .row .col-12:last-child {
        margin-top: 0rem !important;
    }
}

@media screen and (max-width: 768px) {
    .flowly-footer {
        padding: 0px;
    }
        .flowly-footer .container .mt-3, .flowly-footer .container .row .col-12 {
            margin-top: 05px !important;
        }
    .footer-dnone{
        display:none;
    }
    .flowly-footer .logo-container {
        flex-direction:row;
        position:relative;
        height:2.5rem;
    }
    .flowly-footer .list-link {
        margin-top: 10px;
    }
    .flowly-footer .list-link li {
        margin-left: auto;
        margin-right: auto;
    }
    .flowly-footer .footer-contact {
        margin-bottom: 0px;
    }
    .flowly-footer-bottom {
        padding-top: 0px;
    }
    .flowly-footer-bottom p {
        margin :0px;
    }
    .flowly-footer .logo-container .smedia-footer {
        position:absolute;
    }
    .flowly-footer .logo-container .smedia-footer:nth-child(1), .flowly-footer .logo-container .smedia-footer:nth-child(2), .flowly-footer .logo-container .smedia-footer:nth-child(3) {
        width: 100%;
    }
    .flowly-footer .logo-container .smedia-footer:nth-child(2) {
        top: 2px;
    }
    .flowly-footer .logo-container .smedia-footer:nth-child(1) a {
        margin-right: auto;
    }
    .flowly-footer .logo-container .smedia-footer:nth-child(2) a {
        margin-left: auto;
        margin-right: auto;
    }

    .flowly-footer .logo-container .smedia-footer:nth-child(3) ul {
        margin-left: auto;
    }
}

