.b-alt {
    position: relative;
    display: block;
    overflow-x: hidden;
}

.b-alt-flex {
    display: flex;
}

.b-alt-center {
    justify-content: center;
}

.b-alt .b-ellipse {
    position: absolute;
    top: 0;
    left: -20vw;
    width: 140vw;
    height: 35vh;
    z-index: -1;
    opacity: 0.05;
    padding-bottom: 20%;
    background-color: white;
    border-radius: 50%;
    border-top: 10vh solid var(--flowly-orange);
    border-bottom: 30vh solid var(--flowly-orange);
    border-left: 15vw solid var(--flowly-orange);
    border-right: 15vw solid var(--flowly-orange);
    transform: rotateX(55deg);
}


.b-alt .smedia-sidebar {
    position:absolute;
    top: 50px;
    bottom: 0;
    left: 8%;
    width: 40px;
    display:flow;
    align-items: start;
    justify-content: center;
    z-index: 10;
    max-height: 500px;
}

.b-alt .smedia-sidebar .smedia-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.b-alt .smedia-sidebar .smedia-content .smedia-divider {
    width: 2px;
    height: 100px;
    background-color: var(--flowly-orange);
    margin: 8px 0;
}

.b-alt .smedia-sidebar .smedia-content .smedia-text {
    color: var(--flowly-orange);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-transform: uppercase;
}

.b-alt .b-top-gradient, .b-alt .b-bottom-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50%;
    background: linear-gradient(to bottom, var(--flowly-orange) 50%, rgba(0,0,0,0) 100%);
    z-index: -10;
    opacity: 0.2;
}

.b-alt .b-bottom-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 70%, var(--flowly-orange) 100% );
    z-index: -10;
}

.b-alt .b-cover-circle {
    position: absolute;
    top: -1000px;
    /*left: -50%;*/
    width: 3840px;
    height: 1500px;
    z-index: -5;
    opacity: 1;
    padding-bottom: 20%;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.b-full-gradient {
    position: absolute;
    height: 100%;
    width: 100vw;
    background-color: var(--flowly-orange);
    opacity: 0.2;
    top: 0;
    left: 0;
    z-index: -10;
}

@media screen and (max-width: 1199px) {
    .b-alt .b-cover-circle {
        top: -990px;
    }

    .smedia-sidebar {
        display: none !important;
    }
}

@media screen and (max-width: 991px) {
    .b-alt .b-cover-circle {
        top: -980px;
    }

    .smedia-sidebar {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .b-alt .b-cover-circle {
        top: -960px;
    }
}

@media screen and (max-width: 575px) {
    .b-alt .b-cover-circle {
        top: -940px;
    }
}
