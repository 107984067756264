.section-privacy {
    padding: 40px 0;
}

.section-privacy-bg {
    background-color: var(--flowly-section-bg-color);
}

.image-elements img {
    height: 60px;
}

.image-elements{
    text-align: center;
}

.privacy-card {
    width: 100%;
    user-select: none;
    border-radius: 3px;
    background-color: #fff;
    padding: 10px 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 15px;
    height: 120px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.bg-gray {
    background-color: var(--flowly-section-bg-color);
}

@media screen and (max-width: 768px) {
    .privacy-card{
        height: auto;
    }
}

