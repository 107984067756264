.accordion .accordion-item {
    border: 0px;
    border-top: 1px solid var(--flowly-section-bg-color) !important;
}

.accordion .accordion-item:first-of-type {
    border-radius: 0px;
}

.accordion .accordion-item:last-child {
    border-bottom: 1px solid var(--flowly-section-bg-color);
}

.accordion .accordion-header {
    margin: 0px;
}

.accordion .accordion-header button {
    font-size: 27px;
    line-height: 1.07em;
    font-weight: 700;
    color: var(--flowly-heading-color);
    margin: 25px 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    letter-spacing: -.04em;
    margin: 0px;
}

.accordion-header button:not(.collapsed), .accordion-header button:focus {
    background-color: #FFF;
    border: 0px solid #FFF;
    box-shadow: 0 0 0 0 #FFF;
}

.accordion-header button:after {
    background-image: none !important;
    margin-right: 0px !important;
    display: none;
}

.accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 1.5rem;
}

.accordion .accordion-item .accordion-collapse .accordion-body p {
    margin-bottom: 2rem !important;
    text-align: justify;
    text-align-last: center;
}

.accordion .accordion-header .accordion-icon {
    margin-left: auto;
}

.accordion .accordion-header .accordion-icon svg {
    color: #FFF !important;
    fill: var(--flowly-orange) !important;
}

.accordion .accordion-item:first-child .accordion-header .accordion-icon .svg-plus {
    display: none;
}

.accordion .accordion-item .accordion-header .accordion-icon .svg-minus {
    display: none;
}

.accordion .accordion-item:first-child .accordion-header .accordion-icon .svg-minus {
    display: block;
}

.accordion-header button {
    background-color: #F7F6F2 !important;
}
