.hero-section {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 500px;
    background-color: #fff7f0;
}

.bg-light-orange {
    background-color: #fff7f0;
}
.logo-middle {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-section .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: 50%;
}

.hero-img {
    position: absolute;
    height: 450px;
    top: 50px;
    right: -50px;
    width: 700px;
    border-top-left-radius: var(--flowly-border-radius);
    border-bottom-left-radius: var(--flowly-border-radius);
}

.hero-img-mobile {
    display: none;
    margin: 1em;
    height: 250px;
}

.product-card {
    border-radius: 0.5rem;
    padding: 1em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-card img {
    max-height: 70px;
}

.product-card .description {
    padding: 1.5em;
    text-align: start;
}

.floating-nav {
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    border-radius: 20px;
}

.floating-nav .other {
    padding: 0.25em;
    width: fit-content;
    height: fit-content;
    border: 1px solid rgba(0,0,0,0.07);
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floating-nav .other img {
    height: 25px;
    margin-right: 5px;
    float:left;
}

.floating-nav .other p {
    font-weight: bold;

}
    
.floating-nav .back {
    text-align: end;
    color: var(--flowly-orange);
    font-weight: bold;
}

.back_mobile {
    text-align: start;
    color: var(--flowly-orange);
    font-weight: bold;
    position: absolute;
    top: 20px;
    cursor: pointer;
    display: none;
    border-radius: 20px;
    z-index: 1044;
}

  
.rainbow-1 {
    position: absolute;
    left: -50%;
    top: 90%;
    width: 200%;
    z-index: 0;
}

.rainbow-2 {
    position: absolute;
    transform: rotate(180deg);
    left: -50%;
    top: 0;
    width: 200%;
    height: 300%;
    z-index: 0;
}

.block .image {
    height: 150px;
}

.block .image img {
    min-height: 100%;
}

.block div {
    display: flex;
    justify-content: center;
}

.block p {
    padding: 1em;
}

.block div p {
    width: 80%;
    padding: 1em;
}

.privacy-card {
    border-radius: var(--flowly-border-radius);
    background-color: #F7F7F7;
    box-shadow: none;
}

.solution-gif {
    height: 1500px;
}

.reduce-product-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.reduce-product-img img {
    height: 30px;
    max-width: 200px;
}

.solution-gif svg {
    position:relative;
    top: -70px;
}

.flowly-footer {
    z-index: 1045;
    position: relative;
}

@media screen and (min-width: 992px) {

}

@media screen and (max-width: 992px) {

    .hero-section {
        height: 100%;
    }
    .hero-section .title {
        width: 100%;
        align-items: center;
        text-align: center !important;
    }

    .hero-img {
        display: none;
    }

    .hero-img-mobile {
        display: block;
    }

    .product-card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center !important;
        padding: 2em;
    }

    .product-card .description {
        padding: .25em;
        text-align: center;
    }

    .solution-gif {
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width: 1500px) {
    .hero-img {
        width: 500px;
    }
}

@media screen and (max-width:1200px) { }
@media screen and (max-width:767px) {
    .topref h4.resize-floating-nav{
        margin-top: 6rem!important;
    }
    .topref .back {
        display:none;
    }
    .back_mobile {
        display: block;
    }
}
@media screen and (max-width:479px) { }