.carroussels-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.carroussels {
    display: flex;
    overflow: hidden;
    width: 80%;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 20px;
}

.carroussels .row {
    padding: 5px;
}

.carroussels .carroussel {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.carroussel {
    height: 100%;
}

.carroussel .message-content {
    background-color: orange;
    color:white;
    border-radius: 10px;
    padding: 20px;
    margin: 0 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.carroussel .message-title {
    font-size: 18px;
    color: white;
    vertical-align:top
}

.carroussel .message-text {
    color: white;
}

.carroussel .message-text .text {
    text-align: center;
}

.button-container {
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
}
.carroussels-container button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.carroussels-container button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}