:root {
    --loader-background-color: #EEEEEE;
    --loader-highlight-color: #DEDEDE;
}

.image-loader {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: var(--flowly-border-radius);
    padding: 0.75em;
}

.text-loader {
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 3px;
    margin-bottom: 10px;
}

.round-loader {
    width: 100px;
    height: 100px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 50%;
    margin-bottom: 20px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}