.b-alt .b-cover-circle img {
    height: 250px;
    position: absolute;
    bottom: -12px;
}

.section-society {
    padding-top: 1.5rem !important;
    justify-content: center;
    text-align: center;
}
.section-society.top-section {
    padding-bottom:250px;

}


.section-society .smedia-sidebar {
    position: static;
    top: 50px;
    bottom: 0;
    left: 6%;
    width: 40px;
    display: flex;
    align-items: start;
    justify-content: center;
    z-index: 10;
    max-height: 500px;
}

    .section-society .smedia-sidebar .smedia-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .section-society .smedia-sidebar .smedia-content .smedia-divider {
            width: 2px;
            height: 100px;
            background-color: var(--flowly-orange);
            margin: 8px 0;
        }

        .section-society .smedia-sidebar .smedia-content .smedia-text {
            color: var(--flowly-orange);
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            text-transform: uppercase;
        }
.partner-content img{
    width: 150px;
    margin: auto;
}

@media screen and (max-width: 500px) {

    .section-society.top-section h2{
        font-size: 50px;
        margin-bottom:20px;
    }
}