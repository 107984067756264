.team-card {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    border-radius: var(--flowly-border-radius);
}

.team-card .img-team-content {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
}

.team-card .img-team-content img {
    width: inherit;
}

.team-card .team-card-hover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 80%;
    left: 0;
    opacity: 1;
    z-index: 10;
    visibility: visible;
    background-color: rgba(233, 105, 39, 0.7);
    transition: all 0.3s ease-in-out;
    padding: 0.5rem;
    transition: padding-left 0.3s ease;
    transition: all 0.3s ease-in-out;
}

.team-card:hover .team-card-hover {
    opacity: 1;
    visibility: visible;
    padding-left: 0.8rem;
    padding-bottom: 0.8rem;
    transition: all 0.3s ease-in-out;
    top:0;
}

.team-card .team-card-hover .team-top {
    top: 0;
    left: 0;
}

.team-card .team-card-hover .team-top p {
    color: #fff;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    text-align: center;
}

.team-card .team-card-hover .team-top h6 {
    color: #fff;
    margin: 0;
    padding: 0;
    text-align: center;
}

.team-card .team-card-hover .team-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.team-card .team-card-hover .team-body p {
    vertical-align: middle;
    color: #fff;
}

.team-card .team-card-hover .team-body p::before {
    content: '\201C';
}

.team-card .team-card-hover .team-body p::after {
    content: '\201D';
}

.team-card .team-card-hover .team-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0rem;
    display: flex;
    justify-content: center;
    padding-bottom: 0.8rem;
}

.team-card .team-card-hover .team-bottom .team-social-media {
    width: 2rem;
    height: 2rem;
    border-radius: var(--flowly-border-radius);
    border: 2px solid #FFF;
    transition: all 0.3s ease-in-out;
}

.team-card .team-card-hover .team-bottom:hover .team-social-media {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
}