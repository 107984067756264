#root {
    padding-top:70px;
}
.flowly-navbar {
    height: 70px;
    max-height: 70px;
    min-height: 70px;
    position: fixed;
    top:0;
    z-index: 99;
    width:100%;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.flowly-navbar .container, .flowly-navbar .container a {
    height: 100%;
}

.flowly-navbar .container .dropdown-menu a {
    height: 50px;
    max-height: 50px;
    min-height: 50px;
}

.flowly-navbar .container a img{
    height:80%;
    position:relative;
    top: -5px;
}

#offcanvasNavbar-expand-lg .flowly-logo-offcanvas {
    height: 50px;
}

#offcanvasNavbar-expand-lg .flowly-logo-offcanvas img {
    height: 100%;
}

.flowly-navbar .container a {
    display: flex;
    justify-content: left;
    align-items: center;
}

.flowly-navbar .navbar-content a {
    padding-right: 1.2rem !important;
    text-transform: uppercase;
}
.flowly-navbar .navbar-content a.nav-link.active, #offcanvasNavbar-expand-lg a.nav-link.active {
    color: var(--flowly-orange);
}
.flowly-navbar .navlink-outside {
    color: var(--flowly-body-color);
    padding-top: 5px;
    padding-bottom: 5px;
}

.flowly-navbar .navbar-flowly-icon {
    width: 100px;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}

.flowly-navbar .navbar-flowly-icon .navlink-outside {
    color: var(--flowly-body-color);
    padding-top: 15px;
    padding-bottom: 18px;
    display:none;
}

.navbar-language {
    width:30px;
}

.navbar-language .flowly-country-flag {
    top:auto !important;
    height: 15px !important;
}

.flowly-navbar .dropdown .dropdown-menu .dropdown-item {
    width:auto!important;
}

.dropdown-toggle::after {
    margin-top: 0.1em; 
}

.nav-demo, .out-demo {
    text-transform: none!important;
}

.lc-text {
    text-transform: lowercase !important;
    font-weight:600;
}

@media screen and (min-width: 992px) {
    .flowly-navbar .navbar-flowly-icon {
        margin-left: auto;
    }
    .flowly-navbar .navbar-language {
        margin-left: 0rem;
    }
    .flowly-navbar .dropdown .dropdown-menu {
        top: 45px;
        border-radius: 3px;
        border-top: 3px solid var(--flowly-orange);
    }
    .flowly-navbar .dropdown .dropdown-menu .dropdown-item.active, .dropdown-item:active {
        color: var(--flowly-orange);
        text-decoration: none;
        background-color: rgba(0,0,0,0);
    }
    .nav-ml-auto {
        margin-left: auto !important;
    }
    .flowly-navbar .navbar-content a:not(.nav-ml-auto) {
        margin-left: 0.5rem !important;
    }
    .flowly-navbar .navbar-content a:not(.nav-ml-auto) {
        margin-right: 0.5rem !important;
    }
    .flowly-navbar .navbar-content a.default-nav-padding, .flowly-navbar .navbar-content .default-nav-padding a {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        border-bottom: 2px solid rgba(0,0,0,0);
    }
    .flowly-navbar .navbar-content a.nav-link.active, #offcanvasNavbar-expand-lg a.nav-link.active {
        border-bottom: 2px solid var(--flowly-orange);
    }
    .navbar-language .dropdown-menu {
        right:-1rem;
        left:auto;
    }
    .lk-container, .nav-demo {
        display:none!important;
    }
    .out-demo{
        display:block;
    }
}

@media screen and (max-width: 991px) {
    .flowly-navbar .container .navbar-collapse {
        background-color: #fff;
        z-index: 10;
        position: relative;
        top: 20px;
        border-radius: 3px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 3rem;
        flex-direction: row !important;
    }
    .flowly-navbar .container .navbar-toggler {
        order: +1;
    }
    .flowly-navbar .container .navbar-collapse .navbar-content {
        align-items: start;
    }
    .flowly-navbar .container .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    #offcanvasNavbar-expand-lg .dropdown .dropdown-menu {
        border-radius: 0px;
        border: 0;
        border-left : 3px solid var(--flowly-orange);
        margin-left: 10%;
    }
    #offcanvasNavbar-expand-lg .navbar-flowly-icon {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: start;
    }
    #offcanvasNavbar-expand-lg .navbar-flowly-icon .navlink-outside {
        color: var(--flowly-body-color);
        padding-top: 8px;
        padding-bottom: 18px;
        margin-right: 1rem;
        display:block;
    }
    #offcanvasNavbar-expand-lg .dropdown .dropdown-menu .dropdown-item.active, .dropdown-item:active {
        color: var(--flowly-orange);
        text-decoration: none;
        background-color: rgba(0,0,0,0);
    }
    .offcanvas-body {
        border-top: 1px solid var(--flowly-body-color);
        margin: 10px;
    }
    .nav-ml-auto {
        margin-left: 0px;
    }
    .flowly-navbar .navbar-content a.nav-link.active, #offcanvasNavbar-expand-lg a.nav-link.active {
        border-bottom: 2px solid rgba(0,0,0,0)!important;
        transition: none!important;
    }
    #offcanvasNavbar-expand-lg .nav-item a, #offcanvasNavbar-expand-lg .nav-link {
        text-transform: uppercase;
    }
    .out-demo {
        display: none;
    }
    .navbar-language {
        margin-top:1px;
        margin-left:auto;
    }
    .navbar-language .dropdown-toggle {
        align-items: center !important;
        display: flex !important;
        margin-top: 5px;
    }
    .navbar-language .dropdown-menu {
        right: -1rem;
        left: auto;
        position:absolute;
    }
    .lk-container a{
        padding-bottom:0px!important;
    }
    .lk-container p {
        font-size: 10px;
        margin-top:0px;
    }
    .nav-demo {
        display: block;
        width:60%!important;
    }
}
