.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--flowly-section-bg-color);
    text-align: center;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
    color: var(--flowly-body-color);
    font-size: 15px;
    z-index: 999;
    display: flex;
    justify-content: center;
}

.cookie-content-btn {
    margin-top:auto;
    margin-bottom:auto;
}

.cookie-banner button {
    margin: 10px 0 !important;
    margin-left: 1rem !important;
    border: 1px solid var(--flowly-section-bg-color) !important;
}


@media screen and (max-width: 1250px) {
    .cookie-banner button {
        width:80% !important;
        margin-left:auto;
    }
}
