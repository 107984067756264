.testimonials-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.testimonials {
    display: flex;
    overflow: hidden;
    width: 80%;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 20px;
}

.testimonials .row {
    padding: 5px;
}

.testimonials .testimonial {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.testimonial .message-content {
    background-color: #FFF;
    border-radius: 10px;
    padding: 20px;
    margin: 0 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    cursor:pointer;
}

.testimonial .message-content .text-content {
    font-size: 15px;
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;
}

.testimonial.flowly .message-content {
    background-color: #F57F0133;
}

.testimonial .message-content .message {
    font-style: italic;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 0px;
}

.testimonial .icon-content {
    color: var(--flowly-black-red);
    fill: var(--flowly-black-red);
    position: absolute;
    top: -15px;
    right: -8px;
    cursor:pointer;
}

.testimonial .name-content {
    padding: 10px;
    font-size: 18px;
    height: 120px;
    cursor:pointer;
}

.testimonial .name-content .name {
    text-align: center;
}

.testimonials-container button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 10px;
}

.testimonials-container button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.testimonial-modal .modal-content {
    border-radius: var(--flowly-border-radius) !important;
    text-align: center;
}

.testimonial-modal .content {
    border-radius: 0.5rem;
    width: 100%;
    padding: 1em;
    color: var(--flowly-body);
}

@media screen and (max-width: 768px) {
    .testimonials {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .testimonial .message-content {
        margin: 0 !important;
    }
}