.textAccordeon{
    color: black;
   
}
.accordion-button::after {
    margin-left : 40px!important;
}
.politic-section {
     padding-bottom: 40px;
     padding-top: 40px;

}
