@-webkit-keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(calc(-200px *16));
        transform: translateX(calc(-200px * 16));
    }
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(calc(-200px * 16));
        transform: translateX(calc(-200px * 16));
    }
}

.slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 1200px;
}

.flowly-limit-border {
    position: relative;
    max-width: 1200px;
    height: 100px;
    margin: auto;
    overflow: hidden;
}

.flowly-limit-border::before, .flowly-limit-border::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.flowly-limit-border::before {
    left: 0;
    top: 0;
}

.flowly-limit-border::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.slider::before,
.slider::after {
}

.slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    -webkit-animation: scroll 60s linear infinite;
    animation: scroll 60s linear infinite;
    display: flex;
    width: calc(200px * 11);
}

.slider .slide {
    height: 100px;
    width: 200px;
    display:flex;
    align-items:center;
}

.slider .slide a {
    margin-left:auto;
    margin-right:auto;
}
