.contact-elements {
    padding: 3em !important;
}
.contact-elements .wrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--flowly-orange);
    color: white;
    border-radius: var(--flowly-border-radius);
    position: relative;
    padding: 2.5em 0.35em ;
}

.contact-elements .wrapper div {
    text-align: start;
}

.contact-elements .wrapper div .element {
    display: flex;
    align-items: center;
}

.contact-elements .wrapper div .element .icon {
    width: 2rem;
    height: 2rem;
    padding: 5px;
    border-radius: 0.5rem;
    border: 1px solid white;
    margin-right: 5px;
}

.contact-elements .wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1000px;
    height: 100%;
    width: 1000px;
    background-color: #FFF;
}

.contact-form-elements {
    position:relative;
    z-index:10;
}

.contact-form-elements:nth-child(2) {
    opacity:0;
    z-index:5;
    height:0;
}

.contact-elements div {
    padding: 5px 0px;
}

.contact-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:15;
}

.contact-elements div p:first-child{
    font-weight: 600;
}

.contact-form-elements div  {
    padding: 5px;
}

.contact-form-elements textarea {
    resize: none;
    height: 200px;
}
.form-contact .contact-form-input {
    display: block !important;
    width: 100% !important;
    padding: .775rem 1rem !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: var(--bs-gray-700) !important;
    appearance: none !important;
    background-clip: padding-box !important;
    border: 3px solid #E8E8E8;
    border-radius: .475rem !important;
    transition: color .2s ease !important;
    outline: none !important;
    background-color: #E8E8E8;
}

.form-contact .contact-form-input:active, .form-contact .contact-form-input:focus {
    background-color: #D6D6D6;
    border-color: #D6D6D6;
    color: var(--bs-gray-700);
    transition: color .2s ease;
    box-shadow: none;
}

.flowly-btn {
    position: relative !important;
    width: auto !important;
    margin: 0 !important;
    font-family: inherit !important;
    font-size: 14px !important;
    line-height: 1em !important;
    letter-spacing: .03em !important;
    font-weight: 600 !important;
    outline: 0 !important;
    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out !important;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out !important;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    border-radius: 6px !important;
    padding: 15px 20px !important;
    cursor: pointer !important;
}
.flowly-btn:focus, .flowly-btn:hover, .flowly-btn:active, .flowly-btn:disabled {
    border-color: var(--flowly-orange) !important;
    box-shadow: none !important;
}
.flowly-btn.red {
    background-color: var(--flowly-red);
    color: #fff;
}

.flowly-btn.orange {
    background-color: var(--flowly-orange) !important;
    color: #fff !important;
   border-color: #fff ;
}

.feedback-message {
    text-align: left ;
    margin-top:-6px !important;
   border-color: red !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid, .form-control.is-valid, .was-validated .form-control:valid {
    background-image: none !important;
    border-color: var(--bs-gray-100) !important;
    box-shadow: none !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
    border-color: salmon !important;
}

.b-alt .b-ellipse-1, .b-alt .b-ellipse-2 {
    top: 20% !important;
    opacity: 0.05 !important;
}

.fading-white-bg {
    box-shadow: 0px 0px 10px 15px rgba(255, 255, 255, .6);
    background-color: rgba(255, 255, 255, .6);
}

.contact-section {
    padding-top: 1.5rem !important;
    justify-content:center;
    text-align:center;
}

    .contact-section .smedia-sidebar {
        position: static;
        top: 50px;
        bottom: 0;
        left: 2%;
        width: 40px;
        display: flex;
        align-items: start;
        justify-content: center;
        z-index: 10;
        max-height: 500px;
    }

        .contact-section .smedia-sidebar .smedia-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

            .contact-section .smedia-sidebar .smedia-content .smedia-divider {
                width: 2px;
                height: 100px;
                background-color: var(--flowly-orange);
                margin: 8px 0;
            }

            .contact-section.smedia-sidebar .smedia-content .smedia-text {
                color: var(--flowly-orange);
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                text-transform: uppercase;
            }
@media screen and (max-width: 992px) {
    .b-alt .b-ellipse-1, .b-alt .b-ellipse-2 {
        display: none;
    }
    .contact-elements {
        padding: 0 2em !important;
    }
    .contact-elements .wrapper {
        border-top-left-radius: var(--flowly-border-radius);
        border-bottom-left-radius: var(--flowly-border-radius);
        padding: 2.5em;
    }
    .contact-elements .wrapper::before {
        display: none;
    }
    #flowly-contact{
        padding: 1em !important;
    }
    .contact-section, .contact-section-container {
        padding-top: 0px !important;
    }
}

