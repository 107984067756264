.home-section{
    padding: 40px 0;
}

.home-section h5 {
    font-weight: 400;
}

.home-section.top-section{
    padding:0px!important;
    background-color: #FFF;
    border-bottom-right-radius: 20% 30%;
}

    .home-section.top-section.full-screen {

        height: calc(80vh - 20px) !important;
    }

    .home-section .flowly-animation .column-top-section {
    margin-top:auto;
    margin-bottom:auto;
    }

        .home-section .flowly-animation .column-top-section .flowly-subtitle h3 {
            color: var(--flowly-black-red) !important;
            font-weight: 200;
        }

            .home-section .flowly-animation .column-top-section .flowly-subtitle h3 span {
                color: var(--flowly-black-red) !important;
                font-weight: 800;

            }

        .home-section .flowly-animation .column-top-section .btn-content {
            margin-top: 30px;
        }

.btn-content a svg{
        position: relative;
        right: -8px;
    }

    .home-section-bg {
        padding: 40px 0;
        background-color: var(--flowly-section-bg-color);
    }


    .home-section .smedia-sidebar {
        position: static;
        margin-top:44px;
        bottom: 0;
        left: 2%;
        width: 40px;
        display: flex;
        align-items: start;
        justify-content: center;
        z-index: 10;
        max-height: 500px;
    }

        .home-section .smedia-sidebar .smedia-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

            .home-section .smedia-sidebar .smedia-content .smedia-divider {
                width: 2px;
                height: 100px;
                background-color: var(--flowly-orange);
                margin: 8px 0;
            }

            .home-section .smedia-sidebar .smedia-content .smedia-text {
                color: var(--flowly-orange);
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                text-transform: uppercase;
            }

    .home-expertise {
        background-color: #FFF;
    }

    .flowly-animation {
    position:relative;
    height:1px;
        margin-bottom: 32rem;
    }

.flowly-subtitle{
    position:relative;
    z-index:89;
    }

    .flowly-scroll-indicator {
        position: relative;
        z-index: 89;
        animation: bounce 1s infinite alternate;
    }

    .flowly-animation .top-content img {
        margin-top: auto;
        margin-bottom: auto;
    width:120%;
    }

    .flowly-btn {
        width: auto;
        margin: 0;
        font-family: inherit;
        font-size: 14px;
        line-height: 1em;
        letter-spacing: .03em;
        font-weight: 600;
        outline: 0;
        box-sizing: border-box;
        -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
        -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
        transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        padding: 20px 38px;
        cursor: pointer;
    }

        .flowly-btn.red {
            background-color: var(--flowly-red);
            color: #fff;
        }

.btn-solo{
        min-height: 80px;
    }

.btn-solo a{
    position:relative;
    top:0px;
        }

    .accueil-section {
        background-color: var(--flowly-section-bg-color);
    }

    .tag-container {
        width: 400px;
        height: 180px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }

    .carousel.slide {
        min-height: 200px;
    }

    .carousel .carousel-indicators {
        bottom: 6px;
    }

    .carousel .carousel-item {
    position:relative;
        height: 450px;
    }

        .carousel .carousel-item .carousel-caption {
            left: 0px;
            right: 0px;
            bottom: 0px;
            padding-left: 15%;
            padding-right: 15%;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .carousel .carousel-item img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: auto 100%;
        }

    .carousel .subtitle-carousel {
        text-align: justify;
    }

    .carousel-section .flowly-author {
        text-align: right;
    }

    .carousel-section .flowly-dialog {
        font-style: italic;
        text-align: justify;
        font-weight: 400;
        font-size: 18px;
    }

    .carousel-section .flowly-author .flowly-subtext {
        color: var(--flowly-footer);
        font-size: 12px;
    }

    .carousel-section .flowly-dialog::before {
        content: '\201C';
    }

    .carousel-section .flowly-dialog::after {
        content: '\201D';
    }

    .carousel-section .carousel-indicators button {
        background-color: var(--flowly-footer);
    }

    .carousel-section .carousel-item {
        height: 240px;
    }

    .flowly-home-description {
        text-align: center;
    }

        .flowly-home-description p {
            text-align: left;
            margin-bottom: auto;
        }

    .flowly-subcontainer {
        display: flex;
        flex-direction: column;
        height: 105%;
    }

        .flowly-subcontainer p {
            text-align: justify;
        }

    .linkedin-section .bg-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        opacity: 0.5;
        z-index: 1;
    }


    .home-sm-title {
        margin-bottom: 0;
        padding: 0;
    }

    .flowly-home-testimonial .img-title {
        margin-top: 2rem;
        width: 100%;
        position: relative;
    }

        .flowly-home-testimonial .img-title img {
            right: 0px;
            position: absolute;
            width: 80px;
            z-index: -5;
            margin-top: 2rem;
        }

            .flowly-home-testimonial .img-title img:first-child {
                left: 0%;
            }

    .solution-home {
        width: 60% !important;
        margin: auto;
    }

    .solution-home-mobile {
        display: none;
    }

    .home-section-solution {
        position: relative;
        overflow: hidden;
        padding-bottom: 0px !important;
    }

        .home-section-solution .content-section-solution {
            position: relative;
            z-index: 1;
        }

        .home-section-solution h5 {
            margin-bottom: 0px;
        }

        .home-section-solution::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 110px;
            background-color: white;
            z-index: 0;
        }

    .home-expertise {
        padding-top: 0px !important;
    }

    .flowly-home-partner {
        background-color: #FFF;
    }

        .flowly-home-partner .container {
            padding-bottom: 20px;
        }



        @media screen and (min-width: 1200px) {
            .flowly-animation {
                margin-bottom: 25rem;
            }
            .home-section.top-section.full-screen {
                    height:initial;
            }
        }

        @media screen and (max-width: 1400px) {
            .home-section-solution::after {
                /*height: 165px;*/
                height: 105px;
            }

            .btn-solo a {
                /*top: -40px;*/
                top: 0px;
            }
        }

        @media screen and (max-width: 1200px) {
            .flowly-animation {
                margin-bottom: 25rem;
            }

            .carousel .carousel-item {
                height: 380px;
            }

            .carousel-section .carousel-item {
                height: 260px;
            }

            .flowly-animation .top-content img {
                height: 100% !important;
            }

            .flowly-animation .top-content {
                height: 60% !important;
            }

            .flowly-animation .top-text {
                position: relative;
                top: -50px;
            }

                .flowly-animation .top-text .flowly-subtitle, .flowly-animation .top-text .btn-content {
                    text-align: center !important;
                }

            .flowly-home-testimonial .img-title img {
                top: -50px;
            }

            .home-section-solution::after {
                /*height: 153px;*/
                height: 105px;
            }

            .btn-solo a {
                top: 0px;
            }
        }

        @media screen and (max-width: 992px) {
            .flowly-animation {
                margin-bottom: 20rem;
            }

            .flowly-home-content {
                margin-bottom: 4rem;
            }

            .carousel .carousel-item {
                height: 380px;
            }

            .carousel-section .carousel-item {
                height: 280px;
            }

            .flowly-animation .top-text {
                top: -80px;
            }

                .flowly-animation .top-text h3 {
                    font-size: 40px;
                }

            .flowly-home-testimonial .img-title img {
                top: 0px;
            }

            .solution-home {
                display: none;
            }

            .solution-home-mobile {
                display: block;
            }

            .btn-solo a {
                /*top: 30px !important;*/
                top: 7px;
            }

            .home-section-solution::after {
                height: 36px;
            }

            .img-expertise {
                display: none;
            }
        }

        @media screen and (max-width: 768px) {
            .flowly-animation {
                margin-bottom: 15rem;
            }

            .subtitle-carousel {
                font-size: 80%;
                line-height: 1.2;
            }

            .carousel .carousel-item {
                height: 380px;
            }

            .carousel-section .carousel-item {
                height: 320px;
            }

            .flowly-animation .top-text {
                top: -120px;
            }

            .flowly-home-testimonial .img-title img {
                top: -50px;
            }

            .home-section.top-section {
                border-bottom-right-radius: 20% 20%;
            }
        }

        @media screen and (max-width: 576px) {
            .carousel .carousel-item {
                height: 380px;
            }

            .carousel-section .carousel-item {
                height: 330px;
            }

            .flowly-home-testimonial .img-title img {
                top: -50px;
            }

            .home-section.top-section {
                border-bottom-right-radius: 20% 20%;
            }

            .flowly-home-testimonial .img-title img {
                width: 55px;
                top: -35px;
            }

                .flowly-home-testimonial .img-title img:nth-child(2) {
                    display: none;
                }
        }

        @media screen and (max-width: 500px) {

            .carousel-section .carousel-item {
                height: 360px;
            }
        }

        @keyframes bounce {
            0% {
                transform: translateY(0); /* Initial position */
            }

            100% {
                transform: translateY(-5px); /* Bounce 10 pixels up */
            }
        }
