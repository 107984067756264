.section-society {
    padding: 40px 0;
}

.section-society-bg {
    padding: 40px 0;
    background-color: var(--flowly-section-bg-color);
}

.society-content .society-img{
    width: 65%;
    height: auto;
    object-fit: cover;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.society-content .society-img img {
    width: 100%;
    border-radius: 3px
}

.society-content .society-img p, .society-content .society-img h6 {
    margin: 0;
    padding: 0;
}

.society-content .society-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2.5rem;
}

.society-content .society-text p {
    text-align: justify;
}



