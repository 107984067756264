.section-expertise {
    padding: 5em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    min-height: 500px;
}

.section-expertise img {
    position: absolute;
    left: calc(50% - 445px);
    bottom: 0;
    height: 100%;
    z-index: 0;
}

.fading-white-bg {
    box-shadow: 0px 0px 10px 15px rgba(255, 255, 255, .6);
    background-color: rgba(255, 255, 255, .6);
}

.expertise-wrapper {
    width: 100%;
    height: 600px;
    display: flex;
    background-color: transparent;
    padding: 0 !important;
}

.expertise-wrapper .menu {
    width: 30%;
    height: 100%;
    background-color: #F7F6F2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    overflow-y: scroll;
    z-index: 50;
}

    .expertise-wrapper .menu .element {
        width: 100%;
        /*height: 175px;*/
        font-size: 18px;
        background-color: #F7F6F2;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5em;
        font-weight: bold;
        cursor: pointer;
        vertical-align: middle;
    }

    .expertise-wrapper .menu .element.active {
        background-color: #fff;
        border-left: 5px solid var(--flowly-orange);
        font-size: 1.2em;
    }
        .expertise-wrapper .menu .element .soustexte {
            font-size: 0.7em;
            vertical-align: middle;
        } 
.expertise-wrapper .menu .element .icon {
    color: var(--flowly-orange);
}

.expertise-wrapper .content {
    width: 70%;
    height: 100%;
    border-top-right-radius: var(--flowly-border-radius);
    border-bottom-right-radius: var(--flowly-border-radius);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 19;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
}

    .expertise-wrapper .content img {
        max-width: 80%;
        max-height: 80%;
        height: 80%;
    }

.expertise-wrapper .content p {
    padding-top: 3em;
}

.expertise-wrapper-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 0 !important;
}

.expertise-wrapper-mobile .menu {
    width: 100%;
    height: fit-content;
    background-color: #F7F6F2;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    z-index: 50;
}

.expertise-wrapper-mobile .menu .element {
    width: 100%;
    height: 125px;
    font-size: 18px;
    background-color: #F7F6F2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5em;
    font-weight: bold;
    cursor: pointer;
}

    .expertise-wrapper-mobile .menu .element.active {
        background-color: #fff;
        font-size: 18px;
        border-left: 5px solid var(--flowly-orange);
    }


.expertise-wrapper-mobile .menu .element .icon {
    color: var(--flowly-orange);
}

.expertise-wrapper-mobile .content {
    height: 0px;
    background-color: rgba(246,153,1,.1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 19;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.expertise-wrapper-mobile .content.active {
    height: fit-content;
    padding: 2em;
}

.expertise-wrapper-mobile .content.active img {
    display: inline;
    width: 100%;
}

.expertise-wrapper-mobile.active .content p {
    display: inline-flex;
    padding-top: 3em;
}
.hero-section .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: 50%;
}

.hero-img {
    position: absolute;
    height: 450px;
    top: 50px;
    right: -50px;
    width: 700px;
    border-top-left-radius: var(--flowly-border-radius);
    border-bottom-left-radius: var(--flowly-border-radius);
}
.orange-section {
    background-color: #F69900;
    color: white !important;
}
.orange-button {
    color: orange !important;
}
.approach-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #F69900;
    color:white;
    padding: 1em;
    border-radius: var(--flowly-border-radius);
}
.Display-none {
    display: none;
}
.Display-block {
    display: block;
}
.clientscaches {
    display:none;
}
.right-part {
    height: 100%;
    width: 80%;
    flex-direction: column;
}

.approach-card h4 {
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin: 0;
    color: white;
}
.approach-card h4Title {
    font-size: 20px;
    text-align: center;
    color: white;
}
.approach-card h4smalltext {
    font-size: 8px;
    text-align: center;
    color: white;
}

.experience-section {
    position: relative;
}

.experience-section::before {
    content: "";
    top: -25px;
    width: 100%;
    height: 60px;
    background-color: #F69900;
    position: absolute;
    z-index: 48;
}
.clientsdiscover {
        margin-top: 20px;
        display:flex;
        align-items:center;
        text-align:center;
        justify-content:center;
    }
.experience-card {
    z-index: 50;
    height: 350px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

 .experience-card-caches {
    z-index: 50;
    height: 350px;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
} 
.experience-card .last {
        z-index: 50;
        height: 350px;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
.experience-card:nth-child(1) {
    border-top-left-radius: var(--flowly-border-radius);
}
.experience-card:nth-child(2) {
    border-top-right-radius: var(--flowly-border-radius);
}
.experience-card:nth-last-child(1) {
    border-bottom-right-radius: var(--flowly-border-radius);
}
.experience-card:nth-last-child(2) {
    border-bottom-left-radius: var(--flowly-border-radius);
}
.experience-card.blue {
    background-color: #24385E !important;
}
.experience-card.orange {
    background-color: #F69900;
}
    .experience-card.orange .last {
        background-color: #F69900;
        border-bottom-left-radius: var(--flowly-border-radius);
        border-bottom-right-radius: var(--flowly-border-radius);
    }
.experience-card img {
    position:absolute;
    right:0px;
    height: 100%;
    width:auto;
}

.experience-card a {
    width: 100%;
    text-align: end;
    color: white;
    font-weight: light;
}

.experience-card .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #fff;
}

.experience-card .content span {
    margin: 0;
    font-size: 12px;
    color: rgba(255,255,255, 0.75) !important;
}

.experience-card .content h4 {
    margin: 0;
    font-size: 20px;
    color: #fff !important;
}

.experience-card .content p {
    font-size: 14px !important;
    color: #fff !important;
}

.carroussel {
    cursor: pointer;
    user-select: none;
}

.carroussel .message-content {
    height: auto;
}

.carroussel .message-content .message-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
}

.carroussel .message-content .message-text {
    font-size: 15px;
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    color: white;
}

.expertise-modal .modal-content {
    border-radius: var(--flowly-border-radius) !important;
    text-align: center;
}

.expertise-modal .content {
    border-radius: 0.5rem;
    width: 100%;
    background-color: orange;
    padding: 1em;
    color: white;
}

.expertise-modal .content h5 {
    color: white;
}


@media screen and (min-width: 992px) {
    .expertise-wrapper-mobile {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .expertise-wrapper {
        display: none;
    }
    .experience-card:nth-child(1) {
        border-top-left-radius: var(--flowly-border-radius);
        border-top-right-radius: var(--flowly-border-radius);
    }
    .experience-card:nth-child(2) {
        border-top-right-radius: 0;
    }
    .experience-card:nth-last-child(1) {
        border-bottom-left-radius: var(--flowly-border-radius);
        border-bottom-right-radius: var(--flowly-border-radius);
    }
    .experience-card:nth-last-child(4) {
        border-bottom-left-radius: var(--flowly-border-radius);
        border-bottom-right-radius: var(--flowly-border-radius);
    }
}
