.linkedin-post {
    background-color: #fff;
    padding: 1em;
    border-radius: 3px;
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
}

.linkedin-post .header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    text-align: start;
}

.linkedin-post .header .avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.linkedin-post .header .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.linkedin-post .header .user-infos {
    padding-left: 0.75em;
    
}

.linkedin-post .header .user-infos .user-name {
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.linkedin-post .header .user-infos .post-date {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.linkedin-post .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.linkedin-post .content .overlay {
    height: 40%;
    /* background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); 
    position: absolute;
    bottom: 0;
    width: 100%;
}

.linkedin-post .content .post-message {
    text-align: start;
    margin: 6px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    transition: 0.5s ease-in-out;
}

.linkedin-post .content .post-img {
    width: 100%;
}

.linkedin-post .content .post-img img {
    width: 100%;
    border-radius: 3px;
}

.linkedin-post .content .post-link {
    /*text-align: end;*/
    width: 80px;
    height:80px;
    position: absolute;
    bottom: 0.5em;
    right: 0.75em;
    border-radius: 50%;
    background-color: orange;
    right:-40px;
    bottom: -40px;
}

.linkedin-post .content .post-link a {
    font-weight: bold;
    color: #FFF;
    position: absolute;
    left: 12px;
    bottom: 46px;
}