.etude-img img {
    width:100%;
}

.background-image.valence {
    overflow: hidden;
    border-bottom-left-radius: var(--flowly-border-radius);
    border-bottom-right-radius: var(--flowly-border-radius);
    position: relative;
    height: 40vh;
    background-image: url("../../assets/img/experiences/Valence.jpg") !important;
    background-position: center;
    background-size: cover;
}

.etude-img h6{
    margin: 6px 0;
}

.etude-content {
    padding: 0rem 2.5rem;
}

.content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.section-etude .flowly-author {
    text-align: right;
}

.section-etude .flowly-dialog {
    font-style: italic;
    text-align: justify;
    font-weight: 400;
    font-size: 18px;
}

.case-study .block {
    padding: 1em;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.case-study .block .img {
    width: 150px;
    height: 150px;
    background-color: rebeccapurple;
}

.section-etude .flowly-author .flowly-subtext {
    color: var(--flowly-footer);
    font-size: 12px;
}

.section-etude .flowly-dialog::before {
    content: '\201C';
}

.section-etude .flowly-dialog::after {
    content: '\201D';
}