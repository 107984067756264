@font-face {
    font-family: Nunito;
    src: url('../public/static/Nunito/Nunito-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Nunito;
    src: url('../public/static/Nunito/Nunito-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

:root {
    --flowly-heading-color: #24385e;
    --flowly-body-color: #24385e;
    --flowly-link-color: var(--flowly-orange);
    --flowly-black-red: #921B1E;
    --flowly-red: #DC3027;
    --flowly-orange: #f57f01;
    --flowly-yellow: #F69B1F;
    --flowly-section-bg-color: #f2f7ff;
    --flowly-footer: #3A3A3A;
    --flowly-border-radius: 1.5rem;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: var(--flowly-body-color);
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden !important;
    letter-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    color: var(--flowly-heading-color);
    margin: 25px 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    letter-spacing: -.04em;
}

h1 {
    font-size: 80px;
    line-height: .9375em;
}

h2 {
    font-size: 60px;
    line-height: 1.08em;
}

h3 {
    font-size: 50px;
    line-height: 1.02em;
}

h4 {
    font-size: 36px;
    line-height: 1.28em;
}

h5 {
    font-size: 27px;
    line-height: 1.07em;
}

h6 {
    font-size: 20px;
    line-height: 1.2em;
    letter-spacing: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
    color: var(--flowly-orange);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
}

a:hover
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
p a:hover {
    color: var(--flowly-orange);
}

a,
p a {
    color: var(--flowly-link-color);
    text-decoration: none;
    -webkit-transition: color .2s ease-out;
    -moz-transition: color .2s ease-out;
    transition: color .2s ease-out;
}

p a:hover {
    color: var(--flowly-red);

}

p {
    margin: 10px 0;
}

.bg-gray {
    background-color: var(--flowly-section-bg-color);
}

.flowly-btn {
    position: relative;
    width: auto;
    margin: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1em;
    letter-spacing: .03em;
    font-weight: 600;
    outline: 0;
    -webkit-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    padding: 15px 20px;
    cursor: pointer;
    user-select:none;
}

.flowly-btn.red {
    background-color: var(--flowly-black-red);
    color: #fff;
}

.flowly-btn.orange {
    background-color: var(--flowly-orange);
    color: #fff;
}

.section-bg {
    background-color: var(--flowly-section-bg-color);
}

p.text-justify {
    text-align: justify;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.flowly-orange {
    color: var(--flowly-orange);
}

.round-border {
    border-radius: 3px;
}

.toolbar {
    display: none !important;
}